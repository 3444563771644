<template>
  <div>
    <div class="card-toolbar mb-5">

      <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
        <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
      </button>
    </div>

    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20">
          <div class="row">
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="account_no">{{ $t('management_subdomain.assign_account_no') }}</label>
              <input v-model="filters.assign_account_no" type="text" id="account_no" class="form-control">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="company_name">{{ $t('management_subdomain.company_name') }}</label>
              <input v-model="filters.company_name" type="text" id="company_name" class="form-control">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="subdomain">{{ $t('management_subdomain.business_name_subdomain') }}</label>
              <input v-model="filters.business_name_subdomain" type="text" id="subdomain" class="form-control">
            </div>

            <div class="form-group col-md-6 mt-2 mb-2">
              <label>{{ $t('subdomain.plan') }}</label>
              <select name="plan_id" id="filter_plan_id" v-model="filters.plan_id" class="custom-select" :disabled="is_add_payment">
                <option v-for="row in plans_list" :value="row.id" :key="row.id">{{ row.name }}</option>
              </select>
            </div>

            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="from_date">{{ $t('management_subdomain.from_date') }}</label>
              <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="to_date">{{ $t('management_subdomain.to_date') }}</label>
              <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="active_from_date">{{ $t('management_subdomain.active_from_date') }}</label>
              <input v-model="filters.active_from_date" type="date" id="active_from_date" class="form-control">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="active_to_date">{{ $t('management_subdomain.active_to_date') }}</label>
              <input v-model="filters.active_to_date" type="date" id="active_to_date" class="form-control">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="expired_account">{{ $t('management_subdomain.expired_account') }}</label>
              <b-form-checkbox size="lg" v-model="filters.expired_account" name="check-button" switch></b-form-checkbox>
            </div>

            

            <div class="form-group d-inline-flex col-md-6 mt-10">
              <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                                <span><i class="fas fa-search"></i>
                                    <span>{{ $t('search') }}</span>
                                </span>
              </button>
              <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                                <span><i class="fas fa-trash-restore"></i>
                                    <span>{{ $t('reset_search') }}</span>
                                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--begin::User-->
    <div class="card card-custom">
      <div class="card-body">
        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">
          <!--                    <template slot="available_flag" slot-scope="props">-->
          <!--                        <v-icon v-if="props.row.available_flag" class="text-danger">mdi-circle</v-icon>-->
          <!--                        <v-icon v-else class="text-success">mdi-circle</v-icon>-->
          <!--                    </template>-->
          <template slot="available_flag" slot-scope="props">
            <b-form-checkbox v-if="$can('management_subdomains.update')" size="lg" @change="changeStatus(props.row.id, props.row.available_flag)" v-model="props.row.available_flag" :name="'check-button'+props.row.id" switch :key="props.row.id"></b-form-checkbox>
          </template>
          <template slot="actions" slot-scope="props">
            <div class="d-flex">
              <!--                        <v-icon v-if="$can('requests_tenant.update')" small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')"  color="blue darken-2" @click="editItem(props.row.id)">mdi-pencil</v-icon>-->
              <b-dropdown :id="'dropdown-offset_'+props.row.id" :text="$t('commands') " variant="outline-primary" class="m-2">
                  <!-- @click="showModal({expire_date:props.row.expire_date, subscription_id: props.row.subscription_id, row_id: props.row.id})" -->
                  <div v-for="command in commands_list" :key="command.id">
                    <a href="javascript:;" class="dropdown-item"  @click="executeCommand(command.id,props.row.id)">{{command.name}}</a>
                  </div>
                  
              </b-dropdown>
              
              <b-dropdown :id="'dropdown-offset_'+props.row.id" :text="$t('more_actions') " variant="outline-primary" class="m-2">
                <router-link :to="{name: 'management-subdomain.details', params:{id: props.row.id}}" class="dropdown-item">
                  {{ $t('subdomain.details') }}
                </router-link>
                
                <a href="javascript:;" class="dropdown-item" @click="showModal({expire_date:props.row.expire_date, subscription_id: props.row.subscription_id, row_id: props.row.id})">
                  {{ $t('subdomain.change_expire_date') }}
                </a>

                <a href="javascript:;" class="dropdown-item" @click="showModal({plan_id:props.row.plan_id,expire_date:props.row.expire_date, subscription_id: props.row.subscription_id, row_id: props.row.id},'upgrade')">
                  {{ $t('subdomain.upgrade_plan') }}
                </a>


                <a href="javascript:;" class="dropdown-item" @click="showModal({plan_id:props.row.plan_id,expire_date:props.row.expire_date, subscription_id: props.row.subscription_id, row_id: props.row.id},'add_payment')">
                  {{ $t('subdomain.add_payment') }}
                </a>

                <router-link :to="{name: 'management-subdomain.permission_management', params:{plan_id: props.row.plan_id, company_id: props.row.id}}" class="dropdown-item">
                  {{ $t('subdomain.permission_management') }}
                </router-link>

                <router-link :to="{name: 'management-subdomain.print-setting-management', params:{plan_id: props.row.plan_id, company_id: props.row.id}}" class="dropdown-item">
                  {{ $t('subdomain.print_setting_management') }}
                </router-link>

                <router-link :to="{name: 'management-subdomain.invoices-bonds-management', params:{plan_id: props.row.plan_id, company_id: props.row.id}}" class="dropdown-item">
                  {{ $t('subdomain.invoices_bonds_management') }}
                </router-link>

                <router-link :to="{name: 'management-subdomain.code-settings', params:{plan_id: props.row.plan_id, company_id: props.row.id}}" class="dropdown-item">
                  {{ $t('subdomain.code_settings') }}
                </router-link>

              </b-dropdown>
            </div>

          </template>
        </v-server-table>


        <!--end: Datatable-->
      </div>
    </div>
    <!--end::User-->

    <b-modal ref="status-modal" size="md" hide-footer :title="$t('subdomain.change_expire_date')">
      <div>
        <div class="form-group row">
          <div class="col-lg-12 mb-5">
            <label>{{ $t('subdomain.expire_date') }}</label>
            <input type="date" v-model="expire_date" class="form-control" :class="validation && validation.expire_date ? 'is-invalid' : ''">

            <span v-if="validation && validation.expire_date" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.expire_date[0] }}
                </span>
          </div>
        </div>
        <div class="card-footer pl-0 pr-0 pb-0">
          <div class="row">
            <div class="col-lg-6">
              <button type="reset" class="btn btn-primary mr-2" @click="saveExpireDate">{{ $t('save') }}</button>
              <button type="reset" class="btn btn-secondary" @click="hideModal">{{ $t('cancel') }}</button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>


    <b-modal ref="modal_upgrade" size="md" hide-footer :title="is_add_payment ? $t('subdomain.add_payment') : $t('subdomain.upgrade_plan')">
      <div>
        <div class="form-group row">
          <template v-if="is_add_payment">

            <!-- <div class="col-lg-12 mb-5" >
              <label>{{ $t('subdomain.plan') }}</label>
              <select name="plan_id" v-model="plan_id" class="form-control" :class="validation && validation.plan_id ? 'is-invalid' : ''">
                 <option value="1">{{ $t('subdomain.monthly') }}</option>
                 <option value="2">{{ $t('subdomain.yearly') }}</option>
              </select>

              <span v-if="validation && validation.plan_id" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.plan_id[0] }}
              </span>
            </div> -->

          <div class="col-lg-12 mb-5" >
            <label>{{ $t('subdomain.amount') }}</label>
            <input type="number" step="0.01" min="0" v-model="amount" class="form-control" :class="validation && validation.amount ? 'is-invalid' : ''">

            <span v-if="validation && validation.amount" class="fv-plugins-message-container invalid-feedback">
                {{ validation.amount[0] }}
            </span>
          </div>

          <div class="col-lg-12 mb-5" >
              <label>{{ $t('subdomain.subscription_type') }}</label>
              <select name="subscription_type" v-model="subscription_type" class="form-control" :class="validation && validation.subscription_type ? 'is-invalid' : ''">
                 <option value="1">{{ $t('subdomain.monthly') }}</option>
                 <option value="2">{{ $t('subdomain.yearly') }}</option>
              </select>

              <span v-if="validation && validation.subscription_type" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.subscription_type[0] }}
              </span>
            </div>
          </template>
            


          <div class="col-lg-12 mb-5" v-if="!is_add_payment">
            <label>{{ $t('subdomain.expire_date') }}</label>
            <input type="date" v-model="expire_date" class="form-control" :class="validation && validation.expire_date ? 'is-invalid' : ''">

            <span v-if="validation && validation.expire_date" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.expire_date[0] }}
                </span>
          </div>

          <div class="col-lg-12 mb-5">
            <label>{{ $t('subdomain.plan') }}</label>
            <select name="plan_id" id="plan_id" v-model="plan_id" class="custom-select" :disabled="is_add_payment">
              <option v-for="row in plans_list" :value="row.id" :key="row.id">{{ row.name }}</option>
            </select>

            <span v-if="validation && validation.plan" class="fv-plugins-message-container invalid-feedback">
                {{ validation.plan[0] }}
            </span>
          </div>

        </div>
        <div class="card-footer pl-0 pr-0 pb-0">
          <div class="row">
            <div class="col-lg-6">
              <!--   -->
              <button type="reset" v-if="is_add_payment" class="btn btn-primary mr-2" @click="saveAddPayment">{{ $t('save') }}</button>
              <button type="reset" v-else class="btn btn-primary mr-2" @click="saveUpgradePlan">{{ $t('save') }}</button>
              <button type="reset" class="btn btn-secondary" @click="hideModal">{{ $t('cancel') }}</button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  components: {},
  data() {
    return {
      mainRoute: '/subscription/management_subdomains',
      subMainRoute: '/subscription/management_subdomain',
      mainRouteDependency: 'base/dependency',
      changeExpireDateRoute: 'subscription/management_subdomain/expand-date',
      executeCommandRoute: 'subscription/management_subdomain/execute-command',
      upgradePlanRoute: 'subscription/management_subdomain/upgrade-plan',
      addPaymentRoute: 'subscription/management_subdomain/add-payment',
      showAdvancedSearch: false,

      filters: {
        assign_account_no: null,
        business_name_subdomain: null,
        from_date: null,
        to_date: null,
        plan_id : null,
        active_from_date: null,
        active_to_date: null,
        company_name: null,
        expired_account: false,
      },

      status_list: [
        {id: 1, text: this.$t('active')},
        {id: 0, text: this.$t('inactive')},
      ],


      columns: [
        'assign_account_no',
        'login_page',
        'email',
        'db_name',
        'business_name',
        'expire_date',
        'plan_name',
        'available_flag',
        'created_at',
        'actions'
      ],

      validation: [],
      dataList: [],
      expire_date: null,
      amount : null,
      subscription_type : 1,
      plan_id: null,
      subscription_id: null,
      row_id: null,
      plan: null,
      plans_list: [],
      commands_list: [],
      is_add_payment: false,
    }
  },
  computed: {
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          limit: that.$t('records'),
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          assign_account_no: that.$t('management_subdomain.assign_account_no'),
          login_page: that.$t('management_subdomain.business_name_subdomain'),
          email: that.$t('management_subdomain.email'),
          db_name: that.$t('management_subdomain.db_name'),
          business_name: that.$t('management_subdomain.business_name'),
          expire_date: that.$t('management_subdomain.expire_date'),
          plan_name: that.$t('management_subdomain.plan_name'),
          available_flag: that.$t('management_subdomain.available_flag'),
          created_at: that.$t('created_at'),
          actions: that.$t('actions'),

        },
        sortable: ['created_at'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'created_at'},

        alwaysShowPerPageSelect: true,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _filters = {...that.filters};
          _filters.expired_account = that.filters.expired_account ? 1 : 0;
          let _params = {
            ascending: 0,
            byColumn: data.byColumn,
            limit: data.limit,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ..._filters,
          }
          return ApiService.query(that.mainRoute, {..._params}).catch(function (error) {
            console.error(error)
          })

        },
        responseAdapter(resp) {
          that.dataList = resp.data.data.data;
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },
  },
  watch: {
      // user: function (val) {
      //     if (val) {
      //         this.filters.user_id = val.id;
      //     } else {
      //         this.filters.user_id = null;
      //     }
      // },

      subscription_type: function (val) {
        this.amount = (val == 2) ? this.plan.price_per_year : this.plan.price_per_month; 
      }
  },
  mounted() {
    // let that = this;
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.management_subdomain")}]);
    this.getPlans();
    this.getCommands(); 
  },
  methods: {
    getFetch() {
      this.$refs.table.refresh();
    },
    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.plan_id = null;
      this.filters.assign_account_no = null;
      this.filters.business_name_subdomain = null;
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.active_from_date = null;
      this.filters.active_to_date = null;
      this.filters.company_name = null;
      this.filters.expired_account = false;

      this.$refs.table.refresh();
    },
    getPlans() {
      ApiService.get(`${this.mainRouteDependency}/plans`).then((response) => {
        this.plans_list = response.data.data;
      });
    },

    getCommands() {
      ApiService.get(`${this.mainRouteDependency}/commands`).then((response) => {
        this.commands_list = response.data.data;
      });
    },
    
    // actionDelete(id) {
    //     ApiService.delete(this.mainRoute + "/" + id).then((response) => {
    //         this.getFetch();
    //         this.$successAlert(response.data.message)
    //     }).catch(({errors}) => {
    //         console.error(errors);
    //     })
    // },
    // editItem(id) {
    //     this.$router.push({name: 'request-tenant-subdomain.edit', params: {id: id}});
    // },
    // deleteItem(id) {
    //     this.$confirmAlert('', this.actionDelete, id);
    // },
    //
    changeStatus(id, status) {
      ApiService.patch(this.subMainRoute + '/change-status/' + id, {
        available_flag: (status ? '1' : '0'),
      }).then(response => {
        this.$refs.table.refresh();
        this.$successAlert(response.data.message);
      }).catch(error => {
        this.$errorAlert(error);
      });
    },

    async getPlanById(id) {
      await ApiService.get(`subscription/plans/${id}`).then((response) => {
        this.plan = response.data.data;
      });
    },
    // getUsers(filter) {
    //     if(filter && filter.length >= 3){
    //         ApiService.get(`${this.mainRouteDependency}/users`,{params:{filter: filter}}).then((response) => {
    //             this.users = response.data.data;
    //         });
    //     }
    // },
    showModal(data, type = null) {
      this.subscription_id = null;
      this.row_id = null;
      this.expire_date = null;
      
      this.is_add_payment = false;
      this.expire_date = data.expire_date;
      this.subscription_id = data.subscription_id;
      this.row_id = data.row_id;
      if (data.expire_date && data.subscription_id && data.row_id && !type) {
        this.$refs['status-modal'].show();
      } else {
        this.plan_id = data.plan_id;
        this.$refs['modal_upgrade'].show();
        if(type == 'add_payment'){
          this.is_add_payment = true;
          let promise = this.getPlanById(this.plan_id);
          Promise.all([promise]).then(() => {
            this.amount = this.plan.price_per_month;
          });
        }
      }
    },

    executeCommand (command_id, row_id) {
      ApiService.patch(`${this.executeCommandRoute}/${row_id}`, {
        command_id: command_id
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$refs.table.refresh();
        this.hideModal();
      }).catch((error) => {
        this.validation = error.response ? error.response.data.errors : null;
      });
    },
    


    hideModal() {
      this.expire_date = null;
      this.subscription_id = null;
      this.row_id = null;
      this.$refs['status-modal'].hide();
      this.$refs['modal_upgrade'].hide();
    },
    saveExpireDate() {
      ApiService.patch(this.changeExpireDateRoute + '/' + this.row_id, {
        expire_date: this.expire_date,
        subscription_id: this.subscription_id,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$refs.table.refresh();
        this.hideModal();
      }).catch((error) => {
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    saveUpgradePlan() {
      ApiService.patch(`${this.upgradePlanRoute}/${this.row_id}`, {
        expire_date: this.expire_date,
        subscription_id: this.subscription_id,
        plan_id: this.plan_id,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$refs.table.refresh();
        this.hideModal();
      }).catch((error) => {
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    saveAddPayment() {
      ApiService.patch(`${this.addPaymentRoute}/${this.row_id}`, {
        subscription_id: this.subscription_id,
        plan_id: this.plan_id,
        amount : this.amount,
        subscription_type : this.subscription_type
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$refs.table.refresh();
        this.hideModal();
      }).catch((error) => {
        this.validation = error.response ? error.response.data.errors : null;
      });
    }
  },
};
</script>
